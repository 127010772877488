export const isListOrListsPage = (currentPageName?: string) => {
  const pageName = currentPageName?.toLocaleLowerCase();
  return pageName === 'lists' || pageName === 'list';
};

export const isSelectionsDisabled = (
  currentPageName: string,
  isSelectionsDisabled: boolean,
) => {
  return isSelectionsDisabled && !isListOrListsPage(currentPageName);
};
