import { useBi, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  Suspense,
} from 'react';
import { clickButtonInPopup, popupOpened } from '@wix/bi-logger-photo-ugc/v2';
import ReactDOM from 'react-dom';
import { DataCapsule, LocalStorageCapsule } from 'data-capsule';

const SignUpModal = React.lazy(
  () => import('../../components/modals/SignUpModal/SignUpModal'),
);
const SignUpContext = React.createContext({} as SignUpContextValue);

type SignUpContextValue = {
  signUpModalOpen: boolean;
  setSignUpModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

let capsule: DataCapsule | false;

export const SignUpProvider = ({
  children,
  showSignUpModal,
  openWixSignUp,
  onRefuseLoginRequest,
  collectionId,
  albumId,
  instanceId,
  compId,
  enableShowInfoModal,
  stopShowingSignUpModal,
  controllerTime,
}: {
  children: any;
  showSignUpModal?: { res: boolean; origin: string };
  openWixSignUp: (params?: any) => void;
  onRefuseLoginRequest: () => void;
  collectionId?: string;
  albumId: string;
  instanceId?: string;
  compId?: string;
  stopShowingSignUpModal?: boolean;
  enableShowInfoModal?: () => void;
  controllerTime: number;
}) => {
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const origin = useRef('');
  const { isViewer } = useEnvironment();
  const { experiments } = useExperiments();

  const bi = useBi();

  useEffect(() => {
    if (controllerTime) {
      capsule =
        isViewer && LocalStorageCapsule({ namespace: 'media-collections' });
      const checkShouldShowInfoModal = async () => {
        if (enableShowInfoModal) {
          try {
            const savedValue =
              capsule && (await capsule.getItem('showSignUpModal'));
            if (savedValue) {
              enableShowInfoModal();
            }
          } catch (e) {
            if (capsule) {
              capsule.setItem('showSignUpModal', true);
            }
            enableShowInfoModal();
          }
        }
      };
      checkShouldShowInfoModal();
    }
  }, [controllerTime]);

  useEffect(() => {
    if (stopShowingSignUpModal) {
      if (capsule) {
        capsule.setItem('showSignUpModal', false);
      }
    }
  }, [stopShowingSignUpModal]);

  const biDefaultParams = {
    albumID: albumId,
    instance_id: instanceId,
    role: 'UNDEFINED',
    collection_id: collectionId,
  };

  const reportButtonClicked = (buttonName: string) => {
    bi.report(
      clickButtonInPopup({
        ...biDefaultParams,
        buttonName,
        popupName: 'favorite lists login',
      }),
    );
  };
  const CloseModalAndReportClick = (buttonName: string) => {
    onRefuseLoginRequest && onRefuseLoginRequest();
    setSignUpModalOpen(false);
    reportButtonClicked(buttonName);
  };
  useEffect(() => {
    if (showSignUpModal && showSignUpModal.res) {
      origin.current = showSignUpModal.origin;

      setSignUpModalOpen(true);
      bi.report(
        popupOpened({
          ...biDefaultParams,
          origin: origin.current,
          popupName: 'favorite lists login',
        }),
      );
    }
  }, [showSignUpModal]);

  return (
    <SignUpContext.Provider
      value={{
        signUpModalOpen,
        setSignUpModalOpen,
      }}
    >
      {signUpModalOpen &&
        ReactDOM.createPortal(
          <div className={compId}>
            <Suspense fallback={<div />}>
              <SignUpModal
                isOpen={true}
                onSave={async () => {
                  openWixSignUp(); // prompts wix sign up/login modal
                  setSignUpModalOpen(false);
                  reportButtonClicked('sign up');
                }}
                onClose={() => CloseModalAndReportClick('exit')}
                onCancel={() => CloseModalAndReportClick('cancel')}
              />
            </Suspense>
          </div>,
          document.body,
        )}
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUpModal = () => {
  return useContext(SignUpContext);
};
