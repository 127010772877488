import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import s from './Pimple.scss';

export const PIMPLE_STATUS = {
  NEW: 'NEW',
  OFF: 'OFF',
  ON: 'ON',
  PREMIUM: 'PREMIUM',
  ERROR: 'ERROR',
};

type PimpleProps = {
  children: any;
  right: number;
  top: number;
  showPimple: boolean;
};

export const Pimple = ({ children, right, top, showPimple }: PimpleProps) => {
  const status = showPimple ? PIMPLE_STATUS.ERROR : PIMPLE_STATUS.OFF;

  return (
    <div className={s.main}>
      <div
        className={status && cx(s.status, s[status])}
        style={{ top: `${top}px`, right: `${right}px` }}
      />
      {children}
    </div>
  );
};
