import React, { useEffect, useRef, useState } from 'react';
import {
  useEnvironment,
  WidgetProps,
  useTranslation,
  useBi,
} from '@wix/yoshi-flow-editor';
import {
  WixStorageStrategy,
  DataCapsule,
  LocalStorageCapsule,
} from 'data-capsule';
import { st, classes } from './headerIcon.st.css';
import LikeHeart from 'wix-ui-icons-common/on-stage/LikeHeart';
import { Tooltip } from 'wix-ui-tpa';
import { SignUpProvider } from '../../../common/context/SignUpContext/SignUpContext';
import { isListOrListsPage } from '../../../common/headerUtils';
import { shouldAllowSelections } from '../../../common/utils';
import { Pimple } from '../../../common/components/Pimple/Pimple';
import { useBuilderListener } from '@wix/albums-uou-common';
import { photoAlbumGalleryLoaded } from '@wix/bi-logger-photo-ugc/v2';

type HeaderIconProps = WidgetProps & {
  goToCollectionsPage: () => void;
  allowSelections?: boolean;
  isPreview: boolean;
  loggedIn: boolean;
  promptLogin: (params?: any) => void;
  showSignUpModal: { res: boolean; origin: string };
  onRefuseLoginRequest: () => void;
  albumId: string;
  collectionId?: string;
  stopShowingSignUpModal?: boolean;
  enableShowInfoModal?: () => void;
  controllerTime: number;
  currentPageName: string;
  showPimple: boolean;
  isAlbumV8: boolean;
  setPimpleVisibility: (value: any) => void;
  initElementsInEditor?: (
    albumData: AlbumData | AlbumDataRefresh,
    isRefresh: boolean,
  ) => void;
  goToPage: (path: string) => void;
  instanceId: string;
  instance: string;
  currentSet: string;
};

export default (props: HeaderIconProps) => {
  const { isEditor, isMobile } = useEnvironment();
  const {
    enableShowInfoModal,
    stopShowingSignUpModal,
    albumId,
    collectionId,
    showSignUpModal,
    promptLogin,
    onRefuseLoginRequest,
    currentPageName,
    showPimple,
    setPimpleVisibility,
    initElementsInEditor,
    isAlbumV8,
    time,
    goToPage,
    instanceId,
    instance,
    currentSet,
    shouldTriggerCommentsTooltip,
    metaSiteId,
  } = props;
  const [shown, setShown] = useState(props.allowSelections);

  const { dimensions } = props.host;
  const { width, height } = dimensions;
  const { t } = useTranslation();
  const biLogger = useBi();

  const localStorageCapsule = useRef(
    LocalStorageCapsule({ namespace: 'media-collections' }),
  );

  const wixStorageCapsule = useRef(
    new DataCapsule({
      // @ts-expect-error
      strategy: new WixStorageStrategy({
        signedInstance: instance,
      }),
      namespace: 'media-collections',
      scope: metaSiteId,
    }),
  );
  useBuilderListener({
    onNavigateRequest: goToPage,
    onRefreshData: (album: AlbumDataRefresh | AlbumData) => {
      if (initElementsInEditor) {
        initElementsInEditor(album, true);
      } else {
        setShown(
          shouldAllowSelections(
            (album as AlbumDataRefresh)?.albumData,
            currentPageName,
          ),
        );
      }
    },
    currentPage: currentSet,
    timeFromProps: time,
    instanceId,
    instance,
    reportPageLoaded: (loadingTime: number) =>
      biLogger.report(
        photoAlbumGalleryLoaded({
          instance_id: instanceId,
          loading_time: loadingTime,
        }),
      ),
    enable: props.isPreview,
  });

  useEffect(() => {
    const initPimpleState = async () => {
      try {
        const status = await localStorageCapsule.current.getItem(
          'hasModifiedCollection_' + albumId,
        );
        setPimpleVisibility(!!status);
      } catch (e) {}
    };
    initPimpleState();
  }, []);

  useEffect(() => {
    if (showPimple !== undefined) {
      localStorageCapsule.current.setItem(
        'hasModifiedCollection_' + albumId,
        showPimple,
      );
    }
  }, [showPimple]);

  return (
    <SignUpProvider
      showSignUpModal={showSignUpModal}
      openWixSignUp={promptLogin}
      onRefuseLoginRequest={onRefuseLoginRequest}
      collectionId={collectionId}
      albumId={albumId}
      instanceId={props.appParams?.instanceId}
      compId={props.host?.id}
      stopShowingSignUpModal={stopShowingSignUpModal}
      enableShowInfoModal={enableShowInfoModal}
      controllerTime={props.controllerTime}
    >
      <div
        onClick={() => {
          localStorageCapsule.current.setItem(
            'hasModifiedCollection_' + albumId,
            false,
          );
          shouldTriggerCommentsTooltip &&
            wixStorageCapsule.current.setItem(
              'alreadyShownCommentsTooltip_' + albumId,
              true,
            );
          props.goToCollectionsPage && props.goToCollectionsPage();
        }}
        className={st(classes.container, {
          notLoggedIn: !isAlbumV8 && !props.loggedIn,
          isEditor,
        })}
      >
        {(shown || isEditor) && (
          <Tooltip
            content={t('FAVORITES_LISTS_HEADER_ICON_TOOLTIP')}
            disabled={isMobile || shouldTriggerCommentsTooltip}
            appendTo="window"
          >
            <Tooltip
              content={t('COLLECTION_COMMENTS_TOOLTIP')}
              disabled={isMobile || !shouldTriggerCommentsTooltip}
              shown={shouldTriggerCommentsTooltip}
              appendTo="window"
            >
              <Pimple showPimple={showPimple} top={2} right={-1}>
                <LikeHeart width={26} height={26} className={classes.icon} />
              </Pimple>
            </Tooltip>
          </Tooltip>
        )}
      </div>
    </SignUpProvider>
  );
};
